// @font-face {
//   font-family: "Lora-Bold";
//   src: url("/../../fonts/Lora-Bold/Lora-Bold.eot");
//   src: url("/../../fonts/Lora-Bold/Lora-Bold.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Lora-Bold/Lora-Bold.otf") format("opentype"),
//     url("/../../fonts/Lora-Bold/Lora-Bold.svg") format("svg"),
//     url("/../../fonts/Lora-Bold/Lora-Bold.ttf") format("truetype"),
//     url("/../../fonts/Lora-Bold/Lora-Bold.woff") format("woff"),
//     url("/../../fonts/Lora-Bold/Lora-Bold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Lora-Medium";
//   src: url("/../../fonts/Lora-Medium/Lora-Medium.eot");
//   src: url("/../../fonts/Lora-Medium/Lora-Medium.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Lora-Medium/Lora-Medium.otf") format("opentype"),
//     url("/../../fonts/Lora-Medium/Lora-Medium.svg") format("svg"),
//     url("/../../fonts/Lora-Medium/Lora-Medium.ttf") format("truetype"),
//     url("/../../fonts/Lora-Medium/Lora-Medium.woff") format("woff"),
//     url("/../../fonts/Lora-Medium/Lora-Medium.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Lora-Regular";
//   src: url("/../../fonts/Lora-Regular/Lora-Regular.eot");
//   src: url("/../../fonts/Lora-Regular/Lora-Regular.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Lora-Regular/Lora-Regular.otf") format("opentype"),
//     url("/../../fonts/Lora-Regular/Lora-Regular.svg") format("svg"),
//     url("/../../fonts/Lora-Regular/Lora-Regular.ttf") format("truetype"),
//     url("/../../fonts/Lora-Regular/Lora-Regular.woff") format("woff"),
//     url("/../../fonts/Lora-Regular/Lora-Regular.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Lora-SemiBold";
//   src: url("/../../fonts/Lora-SemiBold/Lora-SemiBold.eot");
//   src: url("/../../fonts/Lora-SemiBold/Lora-SemiBold.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Lora-SemiBold/Lora-SemiBold.otf") format("opentype"),
//     url("/../../fonts/Lora-SemiBold/Lora-SemiBold.svg") format("svg"),
//     url("/../../fonts/Lora-SemiBold/Lora-SemiBold.ttf") format("truetype"),
//     url("/../../fonts/Lora-SemiBold/Lora-SemiBold.woff") format("woff"),
//     url("/../../fonts/Lora-SemiBold/Lora-SemiBold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Raleway-Bold";
//   src: url("/../../fonts/Raleway-Bold/Raleway-Bold.eot");
//   src: url("/../../fonts/Raleway-Bold/Raleway-Bold.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Raleway-Bold/Raleway-Bold.otf") format("opentype"),
//     url("/../../fonts/Raleway-Bold/Raleway-Bold.svg") format("svg"),
//     url("/../../fonts/Raleway-Bold/Raleway-Bold.ttf") format("truetype"),
//     url("/../../fonts/Raleway-Bold/Raleway-Bold.woff") format("woff"),
//     url("/../../fonts/Raleway-Bold/Raleway-Bold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Raleway-ExtraBold";
//   src: url("/../../fonts/Raleway-ExtraBold/Raleway-ExtrExtraBold.eot");
//   src: url("/../../fonts/Raleway-ExtraBold/Raleway-ExtraBold.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Raleway-ExtraBold/Raleway-ExtraBold.otf") format("opentype"),
//     url("/../../fonts/Raleway-ExtraBold/Raleway-ExtraBold.svg") format("svg"),
//     url("/../../fonts/Raleway-ExtraBold/Raleway-ExtraBold.ttf") format("truetype"),
//     url("/../../fonts/Raleway-ExtraBold/Raleway-ExtraBold.woff") format("woff"),
//     url("/../../fonts/Raleway-ExtraBold/Raleway-ExtraBold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Raleway-Light";
//   src: url("/../../fonts/Raleway-Light/Raleway-Light.eot");
//   src: url("/../../fonts/Raleway-Light/Raleway-Light.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Raleway-Light/Raleway-Light.otf") format("opentype"),
//     url("/../../fonts/Raleway-Light/Raleway-Light.svg") format("svg"),
//     url("/../../fonts/Raleway-Light/Raleway-Light.ttf") format("truetype"),
//     url("/../../fonts/Raleway-Light/Raleway-Light.woff") format("woff"),
//     url("/../../fonts/Raleway-Light/Raleway-Light.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Raleway-Medium";
//   src: url("/../../fonts/Raleway-Medium/Raleway-Medium.eot");
//   src: url("/../../fonts/Raleway-Medium/Raleway-Medium.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Raleway-Medium/Raleway-Medium.otf") format("opentype"),
//     url("/../../fonts/Raleway-Medium/Raleway-Medium.svg") format("svg"),
//     url("/../../fonts/Raleway-Medium/Raleway-Medium.ttf") format("truetype"),
//     url("/../../fonts/Raleway-Medium/Raleway-Medium.woff") format("woff"),
//     url("/../../fonts/Raleway-Medium/Raleway-Medium.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Raleway-Regular";
//   src: url("/../../fonts/Raleway-Regular/Raleway-Regular.eot");
//   src: url("/../../fonts/Raleway-Regular/Raleway-Regular.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Raleway-Regular/Raleway-Regular.otf") format("opentype"),
//     url("/../../fonts/Raleway-Regular/Raleway-Regular.svg") format("svg"),
//     url("/../../fonts/Raleway-Regular/Raleway-Regular.ttf") format("truetype"),
//     url("/../../fonts/Raleway-Regular/Raleway-Regular.woff") format("woff"),
//     url("/../../fonts/Raleway-Regular/Raleway-Regular.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;

// }

// @font-face {
//   font-family: "Raleway-SemiBold";
//   src: url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.eot");
//   src: url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.eot?#iefix") format("embedded-opentype"),
//     url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.otf") format("opentype"),
//     url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.svg") format("svg"),
//     url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.ttf") format("truetype"),
//     url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.woff") format("woff"),
//     url("/../../fonts/Raleway-SemiBold/Raleway-SemiBold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}
/** Variable declaration ***/
$loraBold : "Lora-Bold";
$loraMedium : "Lora-Medium";
$loraSemiBold : "Lora-SemiBold";
$loraRegular : "Lora-Regular";
$ralewayBold : "Raleway-Bold";
$ralewayMedium : "Raleway-Medium";
$ralewayRegular : "Raleway-Regular";
$ralewaySemiBold : "Raleway-SemiBold";
$ralewayLight : "Raleway-Light";

$fontcolor1 : #E9E9E9;
$fontcolor2 : #141C49;
$fontcolor3 : #585858;
$fontcolor4 : #ffffff;
$fontcolor5 : #454751;
$fontcolor6 : #D06900;
$fontcolor7 : #E4E4E4;

$font400 : 400;
$font500 : 500;
$font600 : 600;
$font700 : 700;
