
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

@font-face {
    font-display: swap;
    font-family: 'Lora-Medium';
    font-weight: normal;
    font-style: normal;
    src:url('../src/Assets/fonts/Lora-Medium/Lora-Medium.woff') format('woff'),
    url('../src/Assets/fonts/Lora-Medium/Lora-Medium.woff2') format('woff2'),
    url('../src/Assets/fonts/Lora-Medium/Lora-Medium.svg') format('svg');
}
@font-face {
    font-display: swap;
    font-family: 'Lora-Regular';
    font-weight: normal;
    font-style: normal;
    src:url('../src/Assets/fonts/Lora-Regular/Lora-Regular.woff') format('woff'),
    url('../src/Assets/fonts/Lora-Regular/Lora-Regular.woff2') format('woff2'),
    url('../src/Assets/fonts/Lora-Regular/Lora-Regular.svg') format('svg');
}
@font-face {
    font-display: swap;
    font-family: 'Lora-Bold';
    font-weight: normal;
    font-style: normal;
    src: url('../src/Assets/fonts/Lora-Bold/Lora-Bold.woff') format('woff'),
    url('../src/Assets/fonts/Lora-Bold/Lora-Bold.woff2') format('woff2'),
    url('../src/Assets/fonts/Lora-Bold/Lora-Bold.svg') format('svg');
}
@font-face {
    font-display: swap;
    font-family: 'Raleway-Bold';
    font-weight: normal;
    font-style: normal;
    src: url('../src/Assets/fonts/Raleway-Bold/Raleway-Bold.woff') format('woff'),
    url('../src/Assets/fonts/Raleway-Bold/Raleway-Bold.woff2') format('woff2'),
    url('../src/Assets/fonts/Raleway-Bold/Raleway-Bold.svg') format('svg');
}
@font-face {
    font-display: swap;
    font-family: 'Raleway-Light';
    font-weight: normal;
    font-style: normal;
    src: url('../src/Assets/fonts/Raleway-Light/Raleway-Light.woff') format('woff'),
    url('../src/Assets/fonts/Raleway-Light/Raleway-Light.woff2') format('woff2'),
    url('../src/Assets/fonts/Raleway-Light/Raleway-Light.svg') format('svg');
}
@font-face {
    font-display: swap;
    font-family: 'Raleway-Medium';
    font-weight: normal;
    font-style: normal;
    src: url('../src/Assets/fonts/Raleway-Medium/Raleway-Medium.woff') format('woff'),
    url('../src/Assets/fonts/Raleway-Medium/Raleway-Medium.woff2') format('woff2'),
    url('../src/Assets/fonts/Raleway-Medium/Raleway-Medium.svg') format('svg');
}
@font-face {
    font-display: swap;
    font-family: 'Raleway-Regular';
    font-weight: normal;
    font-style: normal;
    src: url('../src/Assets/fonts/Raleway-Regular/Raleway-Regular.woff') format('woff'),
    url('../src/Assets/fonts/Raleway-Regular/Raleway-Regular.woff2') format('woff2'),
    url('../src/Assets/fonts/Raleway-Regular/Raleway-Regular.svg') format('svg');
}

a{
    text-decoration: none;
}
.carouselData {
    width: 230px;
}
.MuiTableCell-root {
    border-bottom: 0px !important;
}
@media(min-width: 769px){
    .Ham {
        display: none;
    }

}
@media(max-width:768px){
    .headerNav {
        display: none !important;
    }
    .Ham {
        z-index: 999;
        position: absolute;
    }
    .Ham ul{
      overflow-y: auto;
      height: 435px;
      position: fixed;
      top: -16px;
      left: 0px;
      width: 100%;
      right: 0px;
    }
    .Ham .ieRzUU{
      top: 15px;
    }
    .fqaAndContact {
        display: none !important;
    }
}
.signBtn {
    background:rgb(208, 105, 0);
    padding: 10px 20px 10px 25px;
    color: white !important;
}
.exploreBtn {
    margin-bottom: 15px;
    font-family: 'Lora';
    font-weight: 500;
    color: #717171;
    font-size: 14px;
    letter-spacing: 0.84px;
    padding-left: 30px;
}
.pagination{
    text-align: center;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.pagination li{
  margin: 0 10px;
}
.pagination li a{
    color: #D2D2D2;
    padding: 5px 12px;
    font-family: 'Lora';
}
.pagination .undefined,.pagination li a:hover{
    background-color: #141C49;
    border-radius: 100%;
    color: #fff;
}
.lex-web-ui-iframe--minimize{
  min-width: 85px !important;
}
#lex-web-ui .btn--floating .btn__content{
    background: #d06900 !important;
}
#lex-web-ui .toolbar__content{
    background: #d06900 !important;
}
.rcc-confirm-button{
    background: #ffb300 !important;
    box-shadow: none;
    color: white !important;
    font-family: 'Raleway';
    font-size: 18px;
  }
  .rcc-decline-button{
    background: #ffb300 !important;
    box-shadow: none;
    color: white !important;
    font-family: 'Raleway';
    font-size: 18px; 
  }
iframe {
    border: none;
}
.lex-web-ui-iframe--minimize {
    display: none !important;
}
.pagination li>a:last-child:hover{
    border-radius: 0;
    background-color: transparent !important;
    color: #D2D2D2;
}