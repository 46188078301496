.toster{
    display: block;
    position: fixed;
    top: 100px;
    right: 10px;
    z-index: 1000000000;
    width: 40%;

    &__wrapper{
       display: flex;
       justify-content: space-between;
       border-radius: 10px;
       padding: 0 15px;
       box-sizing: border-box;
       //border: 1px solid #f5c6cb;
       font-family: 'Raleway-Medium';
       p{
        width: 95%;
        padding: 12px 15px;
        color: #fff;

       }
       button{
        background: transparent;
        border: none;
        font-size: 20px;
        color: #fff;
        width: 5%;
        outline: none;
       }

    }
}

@media all and (max-width: 767px) and (min-width: 320px) {
    .toster {
        width: 90%;
        &__wrapper {
            p {
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
  }
