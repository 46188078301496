

.payment{
     font-family:Lora;
     font-size:40px;
     margin-top: 150px;
     font-style:normal;
     color:#141C49;
     padding-bottom: 5px;
}

.lock{
    border-radius:0px !important;
    height:20px !important;
    width: 18px !important;
 }

 .paymentCaption{
     color:#141C49;
     font-size:14px;
     margin: 6px;
     font-family: 'Raleway-Regular';
}

.paper1{
    height:'auto';
    margin-top:60px;
    background-color:white;
    box-shadow:0px 3px 20px #141C491F !important;
    border-radius: 0px !important;
 }

 .paper1Padding{
     padding-top:34px;
     padding-right:50px;
     padding-left:40px;
     padding-bottom:50px;
}

.paper1Title{
    color:#141C49;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Raleway';
}

.paper1TextSaved{
    padding-top:25px;
    font-size:16px;
    color:#141C49;
    font-weight:600;
    font-family: 'Raleway';
 }

 .paper1RemainingText{
    padding-top:40px;
    font-size:16px;
    color:#141C49;
    font-weight:600;
 }

 .paper1DebitDescription{
     color:#585858;
     font-size:12px;
     font-weight:600;
     padding-top:17px;
}

.paper1DebitSubText{
    padding-top:20px;
    font-size:14px;
    color:#141C49;
    font-weight:600;
 }

 .paper1Inputs{
     width:400px;
     border:1px solid #4A4A4A40;
     height:40px;
     margin-top: 12px;
     font-family: Raleway;
     padding-left: 12px;
}

.paper1Inputs::placeholder{
    color:#707070;
}
.paper1selectDate{
    margin-top:12px;
    width:187px;
    border:1px solid #4A4A4A40;
    height:40px;
}

.paper1selectYear{
    margin-top:12px;
    width:187px;
    border:1px solid #4A4A4A40;
    height:40px;
    margin-left: 30px;
}

// .success-div{
//     width: 100%;
//     height: 100%;
//     top: 0;
//     position: absolute;
//     background-color: #141C49;
//     opacity: 80%;
//     margin: 0 !important;
//     left: 0;
//     z-index: 999;

// }
// .success-text{
//     text-align: center;
//     position: relative;
//     width: 30%;
//     padding: 20px;
//     font-size: 20px;
//     margin: 0 auto;
//     margin-top: 10%;
//     margin-left: 50%;
//     border-radius: 10px;
//     top: 25%;
//     background-color: #fff;
//     transform: translate(-50%, -50%);
//     z-index: 999;

// }
// .success-button{
//     height: 60px;
//     width: 210px;
//     margin-top: 70px;
//     background-color: rgb(208, 105, 0) !important;
//     color: white !important;
//     border: 1px solid rgb(208, 105, 0) !important;
//     font-size: 16px;
//     font-family: Raleway;
//     font-weight: bold;
//     margin-left: 40%;
//     margin-right: 40%;
// }
.paper1Padding .MuiFormLabel-root{
    display: block!important;
    padding: 15px 10px!important;
    color: #141C49;
    font-family: 'Raleway-Medium';
    font-weight: 600;
    padding-left: 0px !important;
}

.proceed-button button{
    height:60px;
    width:210px;
    margin-top:0px;
    background-color:#D06900;
    color:white;
    border:1px solid #D06900;
    font-size:16px;
    font-family:'Raleway-Bold';
    font-weight:bold;
    cursor: pointer;
    margin-bottom: 50px;
    &:hover{
      color: #D06900;
      background:#fff;
    }
}
.paymentInfo {
    display: flex;
    margin-bottom: 50px;
    @media (max-width: 768px) {
        display: block;
    }
    .paper1Padding input{
        width: 75%;
        padding: 20px;
        border:1px solid #4A4A4A40;
        font-family: 'Raleway-Regular';
        @media (max-width: 768px) {
            width: 90%;
        }

    }
    .orderSum {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        font-family: 'Raleway';
        font-style: normal;
        color: rgba(20, 28, 73, 1);
    }
    .orderSummary {
        font-size: 16px;
        font-family: 'Raleway-Regular';
        font-style: normal;
        letter-spacing: 0px;
        color: rgba(88, 88, 88, 0.87);
        padding-top: 30px;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
    }
    .paper2 {
        height:'auto';
        margin-top:60px;
        margin-left:24px;
        background-color:white;
        box-shadow:0px 3px 20px #141C491F !important;
        border-radius: 0px !important;
        @media (max-width: 768px) {
         margin-left: 0;
        }
    }
    .coupon-input{
        width: 75%;
        padding: 20px;
        border: 1px solid #4A4A4A40;
        font-family: 'Raleway-Regular';
        margin-top: 20px;
    }
    .coupon-input-applied{
        width: 75%;
        padding: 20px;
        border: 1px solid #4A4A4A40;
        font-family: 'Raleway-Regular';
        margin-top: 20px;
        pointer-events: none;
        background-color: #f2f2f0;
        color : #000
    }
    .apply-button{
        margin-top: 10px;
        background-color: #d06900;
        height: 45px;
        width: 92px;
        font-family: Raleway;
        text-transform: inherit;
        font-weight: bold;
        color: white;
        font-size: 20px;
        border-radius: 0px;
        border: none;
        box-shadow: 1px 1px 1px #636359;
    
    }
    .apply-button:hover{
        background-color: #fff;
        color: #000;
    }
    .error-msg{
        color :red;
        font-family: 'Raleway-Regular';
        padding:0;
        margin-top: 5px;

    }
    .success-msg{
        font-family: 'Raleway-Regular';
        color : green
    }
}
